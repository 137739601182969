
.container {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.section {
  padding: 30px 0;

  @include respond(tablet) {
    padding: 75px 0;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.btn {
  display: inline-block;
  margin-bottom: 20px;
  padding: 16px 38px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 6px;
}

.btn-red {
  background: $bg-red;
  color: $white;

  &:hover {
    background: $bg-red-hover;
  }
}

.btn-white {
  background: $white;
  color: $brand-red;
  border: 1px solid $brand-red;
  overflow: hidden;

  &:hover {
    background: transparent;
    border: 1px solid $white;
    color: $white;

    &.btn--flourish {
      &::after {
        background: white;
      }

      &::before {
        border-left-color: #b91623;
      }
    }
  }

  &.btn--flourish {
    position: relative;
    padding-right: 100px;

    &::after {
      content: "";
      background: #841417;
      width: 50px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }

    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      right: 18px;
      top: 19px;
      z-index: 10;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #e8dbdc;
    }
  }
}

.btn--full {
  width: 100%;
  text-align: center;
  padding: 24px 0;
}
