
.section-vision {
  background: $bg-red;
  color: $white;
}

.vision-text {
  flex: 5;
  padding-right: 40px;
}

.vision-image {
  text-align: right;
  display: none;

  @include respond(tablet) {
    display: block;
    flex: 4;
  }
}