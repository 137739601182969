
.site-header { 
  position: fixed;
  z-index: 100;
  top: 0; 
  left: 0;
  width: 100%; 
  background-color: $white;
  padding: 12px 0;
  border-bottom: 1px solid rgba($black, .1);
}

.header-wrap {
  justify-content: space-between;
}

.site-logo img {
  display: block;
}

.site-nav-menu {
  display: none;

  @include respond(tablet) {
    display: flex;
  }
}

.site-nav {
  align-items: center;
}

.site-nav a {
  display: block;
  padding: 10px 16px;
  color: rgba($color-body, .75);
  text-decoration: none;

  &:hover {
    color: rgba($color-body, 1);
  }
}

.arrow-down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba($color-body, 1);
}
.arrow-right {

  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba($color-body, .75);
  border-top: 5px solid transparent;
}

.investor-menu-button {
  padding-right: 10px;
  overflow: visible;
}

.investor-menu-button::after {
  content: "";
  display: inline-block;
  position: relative;
  left: 3px;
  width: 0; 
  height: 0; 
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba($color-body, .75);
  border-right: 5px solid transparent;
  border-top: 5px solid transparent;
  margin-left: 5px;
}

.investor-menu {
  display: inline-block;
  position: relative;
  color: rgba($color-body, .75);
  text-decoration: none;
}

.investor-menu-item {
  padding: 10px 16px;
}

.investor-menu-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
  min-width: 160px;
  z-index: 1;
}

.investor-menu:hover .investor-menu-content { display: block; }
.investor-menu:hover .investor-menu-button { color: rgba($color-body, 1); }

.investor-menu:hover .investor-menu-button::after {
  border-left: 5px solid transparent;
  border-top: 5px solid rgba($color-body, 1);
  left: 0px;
  top: 3px;
}

.mobile-menu-toggle {
  display: block;
  margin-left: 16px;
  width: 27px;
  height: 27px;
  background: url('../images/icon-menu.png') no-repeat center/contain;

  &.active {
    background: url('../images/icon-menu-close.png') no-repeat center/contain;
  }

  @include respond(tablet) {
    display: none!important;
  }
}

.site-nav-mobile-menu {
  position: fixed;
  top: 68px;
  left: 0;
  right: 0;
  background: #880000;

  a {
    cursor: pointer;
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

.securitize-buttons {
  border-right: 1px solid rgba(black, .1);

  a {
    color: $brand-red;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: $brand-red;
    }
  }

  @include respond(tablet) {
    border-left: 1px solid rgba(black, .1);
    border-right: none;

    a {
      padding-right: 0;
    }
  }
}