
@mixin respond($breakpoint) {
  @for $i from 1 through length($bp-names) {
    $name: nth($bp-names,$i);

    @if $breakpoint == $name {
      @media screen and (min-width: nth($bp-sizes,$i)) {
        @content;
      }
    }
  }
}