
.section-footer {
  background: $brand-gray;
  text-align: center;

  @include respond(tablet) {
    padding: 100px 0 0 0;
  }
}

.form-footer {
  background: $brand-gray;
  text-align: center;

  @include respond(tablet) {
    padding: 10px 0 0 0;
  }
}