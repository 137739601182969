
.section-featured-news {
  padding-bottom: 0;
}

.featured-news-list {
  justify-content: space-between;
}

.featured-news-list-item {
  flex-basis: 100%;
  padding: 20px 20px 40px;
  text-align: center;
  border-radius: 6px;
  transition: .2s;
  box-shadow: 0 10px 30px rgba($black, .1);

  &:hover {
    box-shadow: 0 30px 80px rgba($black, .15), inset 0 -4px 0 $brand-red;
  }

  @include respond(tablet) {
    flex-basis: 30%;
  }
}

.news-image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
}

.news-list {
  justify-content: space-between;
  align-items: center;
}

.news-list-item {
  flex-basis: 50%;
  margin-top: 30px;
  text-align: center;

  @include respond(tablet) {
    flex-basis: 25%;
  }

  img {
    max-width: 125px;
    max-height: 100px;
  }
}