
.section-events {
  background: $brand-gray;
}

.event-list {
  columns: 1;

  @include respond(tablet) {
    columns: 2;
  }
}

.event-item {
  flex-wrap: nowrap;
  margin-bottom: 15px;
  padding-right: 20px;
  page-break-inside: avoid;
  break-inside: avoid;
}

.event-date {
  min-width: 145px;
  font-weight: 600;
}

.event-name {
  line-height: 1.3;
}
