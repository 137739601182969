
.section-hero {
  padding: 100px 0 40px;
  background: $brand-red; // linear-gradient(to right,#c31b2c,#9c050a);
  color: white;

  @include respond(tablet) {
    padding: 130px 0 60px;
  }
}

.section-form {
  padding: 100px 0 40px;
  background: $brand-red;
  text-align: center;

  @include respond(tablet) {
    padding: 130px 0 60px;
  }
}

.hero-content {
  align-items: center;
  text-align: center;

  p {
    max-width: 100%;
    font-size: 22px;
  }
}

.hero-text {
  flex-basis: 100%;
}

.hero-image {
  background-image: url('../images/header-image-globe.png');
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
  flex-basis: 100%;
}


.hero-text {
  h2, h3 {
    text-transform: uppercase;
  }

  h2 {
    margin: 20px 0px 20px;
    font-size: 50px !important;
    @include respond(desk) {
      font-size: 60px !important;
      margin: 20px 0px;
    } 
  }

  h3 {
    margin-bottom: 20px;
    font-size: 0.9em;
    @include respond(tablet) {
      font-size: 1.17em;
    } 
  }
}

.hero-text {
  padding-right: 20px;
}

.hero-image {
  display: none;
  
  @include respond(tablet) {
    display: block;
  }
}

.hero-column-container {
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.one-half {
  flex-basis: 100%;

  @include respond(tablet) {
    flex-basis: 50%;
    flex: 1;
  }
}

.mobile-only {
  @include respond(tablet) {
    display: none;
  }
}

.desktop-only {
  display: none;

  @include respond(tablet) {
    display: block;
  }
}

.entoro {
  > * {
    display: inline-block;
    vertical-align: middle;
  }

  span {
    font-size: 14px;
    color: rgba($black, .55);
  }

  img {
    mix-blend-mode: darken
  }
}

#entoro-modal {
  max-width: 600px;
  padding-top: 40px;
  text-align: center;

  p {
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
  }
}

.btn-wrap {
  background-color: $brand-gray;
  margin: 0 -30px -15px;
  padding: 25px 30px 5px;
  border-radius: 0 0 8px 8px;
}

.go-back {
  display: block;
  font-size: 12px;
  color: rgba($color-body, .5);
}

.cta-floating {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 20px;

  a {
    box-shadow: 0 15px 40px rgba($black, .15);
  }
}
