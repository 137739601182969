
.section-advisors,
.section-team {
  border-bottom: 1px solid rgba($black, .1);
}

.advisor-item {
  margin-top: 20px;
  flex-wrap: nowrap;
  flex-basis: 100%;

  @include respond(tablet) {
    flex-basis: 50%;

    .section-team & {
      flex-basis: 33.3333%;
    }
  }
}

.advisor-picture {
  flex-basis: 100px;
  transition: .2s;

  &:hover {
    opacity: .75;
  }
}

.advisor-info {
  flex-basis: calc(100% - 100px);
  padding: 10px 20px 0;

  h3, h4 {
    margin: 0;
  }

  h3 {
    margin-bottom: 5px;
    font-size: 18px;
    color: $brand-red;

    .section-team & {
      margin-bottom: 10px;
    }
  }

  h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
    color: rgba($color-body, .75);
    font-size: 15px;
    line-height: 1.5;
  }
}

.section-ldc-advisors {
  border-bottom: 1px solid rgba($black, .1);
}

.ldc-advisor-list {
  justify-content: space-between;
}

.ldc-advisor-list-item {
  flex-basis: 50%;
  margin-top: 30px;
  text-align: center;

  @include respond(tablet) {
    flex-basis: 25%;
  }

  img {
    width: 150px;
  }
}